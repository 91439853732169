import Image from "next/image";
import BrandLogo from "@/components/base/brandLogo/brandLogo";
import styles from "./sidebar-menu.module.css";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Link from "next/link";

type Props = {
  openSidebar: boolean;
};

type ListType = {
  _id: number | null;
  title: string;
  icon: string;
  url: string;
};

const SidebarMenu: React.FC<Props> = ({ openSidebar }) => {
  const { t } = useTranslation("atwallet");

  const router = useRouter();
  const sidebarList: ListType[] = [
    { _id: 1, title: t("text.sidebar.dashboard"), icon: "dashboard", url: "/" },
    {
      _id: 2,
      title: t("text.sidebar.coins"),
      icon: "donut_large",
      url: "/coins",
    },
    {
      _id: 3,
      title: t("text.sidebar.wallets"),
      icon: "account_balance_wallet",
      url: "/wallets",
    },
    {
      _id: 4,
      title: t("text.sidebar.exchanges"),
      icon: "currency_exchange",
      url: "/exchanges",
    },
    {
      _id: 5,
      title: t("text.sidebar.arbitrage"),
      icon: "price_change_outlined",
      url: "/arbitrage",
    },
    {
      _id: 6,
      title: t("text.sidebar.orders"),
      icon: "list_alt",
      url: "/orders",
    },
    // {
    //   _id: 7,
    //   title: t("text.sidebar.reports"),
    //   icon: "pie_chart_outlined",
    //   url: "/reports",
    // },
    {
      _id: 8,
      title: t("text.sidebar.trade"),
      icon: "query_stats",
      url: "/trade",
    },
    /* {
      _id: 9,
      title: t("text.sidebar.markets"),
      icon: "store",
      url: "/markets",
    }, */
  ];

  const sidebarExtraList: ListType[] = [
    /* {
      _id: 2,
      title: t("text.sidebar.help"),
      icon: "contact_support",
      url: "/support",
    }, */
  ];

  return (
    <div
      className={
        openSidebar ? styles.sidebarContainer : styles.sidebarContainerClose
      }
    >
      {openSidebar ? (
        <BrandLogo />
      ) : (
        <div className={styles.brandIcon}>
          <Image
            alt="AtWallet Brand Logo"
            src="/images/atWallet_icon.svg"
            width={40}
            height={40}
          />
        </div>
      )}

      <ul className={styles.listWrapper}>
        {sidebarList.map((item) => (
          <li key={item._id} className={styles.listOptions}>
            <Link
              href={item.url}
              className={
                router.pathname === item.url
                  ? styles.listOptionSelected
                  : styles.listOption
              }
            >
             
                <span className="material-icons">{item.icon}</span>
              
              {openSidebar && <p>{item.title}</p>}
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.listBottom}>
        <hr />
        <ul className={styles.listContainer}>
          {sidebarExtraList.map((item) => (
            <li key={item._id} className={styles.listOptions}>
              <Link
                href={item.url}
                className={
                  router.pathname === item.url
                    ? styles.listOptionSelected
                    : styles.listOption
                }
              >
                <div>
                  <span className="material-icons">{item.icon}</span>
                </div>
                {openSidebar && <p>{item.title}</p>}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarMenu;
