import { useEffect, useRef, useState } from "react";
import styles from "./deleteWalletModal.module.css";
import lottie from "lottie-web";
import { useSession } from "next-auth/react";
import { WalletType } from "@/_core/models/walletType";
import { deleteWallet } from "@/_core/services/walletsService";
import { useTranslation } from "react-i18next";

interface Props {
     selectedWallet: WalletType | null;
     setSelectedWallet: React.Dispatch<React.SetStateAction<WalletType | null>>;

     walletRefetch: boolean;
     setWalletRefetch: React.Dispatch<React.SetStateAction<boolean>>;

     deleteModal: boolean;
     setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteWalletModal: React.FC<Props> = ({ selectedWallet, setSelectedWallet, walletRefetch, setWalletRefetch, deleteModal, setDeleteModal }) => {
     const [success, setSuccess] = useState<boolean>(false);
     const animationContainer = useRef<HTMLDivElement | null>(null);
     const successContainer = useRef<HTMLDivElement | null>(null);
     const { data: session, status } = useSession();
     const { t } = useTranslation("atwallet");
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);

     useEffect(() => {
          let animationInstance: any;

          // Initialize Lottie animation only if loading is true and the container is defined
          if (!success && animationContainer.current) {
               animationInstance = lottie.loadAnimation({
                    container: animationContainer.current,
                    renderer: "svg",
                    loop: true,
                    autoplay: true,
                    path: "/animations/attention.json",
               });
          }

          // Cleanup function to destroy the animation when the component is unmounted
          return () => {
               if (animationInstance) {
                    animationInstance.destroy();
               }
          };
     }, [success]);

     useEffect(() => {
          let successInstance: any;

          // Initialize Lottie animation only if loading is true and the container is defined
          if (success && successContainer.current) {
               successInstance = lottie.loadAnimation({
                    container: successContainer.current,
                    renderer: "svg",
                    loop: false,
                    autoplay: true,
                    path: "/animations/delete_animation.json",
               });
          }

          // Cleanup function to destroy the animation when the component is unmounted
          return () => {
               if (successInstance) {
                    successInstance.destroy();
               }
          };
     }, [success]);

     const walletDeleteHandler = async () => {
          if (session?.access_token && selectedWallet?.id) {
               try {
                    await deleteWallet(session.access_token, selectedWallet.id);
                    setWalletRefetch(true); // Trigger refetch of wallets
                    // Close modal after deletion
                    setSuccess(true);
               } catch (error) {
                    console.error("Error deleting wallet:", error);
                    alert(error instanceof Error ? error.message : "Failed to delete the wallet");
               }
          } else {
               alert(t("text.error.missingToken"));
          }
     };

     return (
          <div className={styles.modal_background}>
               <div className={screenSize == "large" ? styles.modal_wrapper : styles.resModal_wrapper}>
                    {!success ? (
                         <div className={styles.modalContentWrapper}>
                              <div className={styles.animation} ref={animationContainer}></div>
                              <h2 className={styles.modalHeroText}> {t("text.wallet.wallet_delete_confirm_question_one")}</h2>
                              {screenSize == "large" ? <h4>{t("text.wallet.wallet_delete_confirm_question_two")}</h4> : <h5>{t("text.wallet.wallet_delete_confirm_question_two")}</h5>}
                              <h4 className={styles.modalHeroText}> {t("text.wallet.wallet_delete_alert_text")}</h4>
                              <div className={styles.modalActions}>
                                   <button
                                        className="secondaryButton"
                                        onClick={() => {
                                             setDeleteModal(!deleteModal);
                                             setSelectedWallet(null);
                                        }}
                                   >
                                        <span className="material-icons">cancel</span>
                                        {t("text.buttons.cancel")}
                                   </button>
                                   <button onClick={walletDeleteHandler} className="deleteButton">
                                        <span className="material-icons">delete</span>
                                        {t("text.buttons.delete")}
                                   </button>
                              </div>
                         </div>
                    ) : (
                         <div className={styles.modalContentWrapper}>
                              <div className={styles.trashAnimation} ref={successContainer}></div>
                              <h2> {t("text.wallet.wallet_deleted_success")}</h2>
                              <div className={styles.modalActions}>
                                   <button onClick={() => setDeleteModal(!deleteModal)} className="primaryButton">
                                        {t("text.buttons.done")}
                                   </button>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     );
};

export default DeleteWalletModal;
