"use client";

import React, { ReactNode, useEffect, useState } from "react";
import MainNavbar from "../mainNavbar/main-navbar";
import SidebarMenu from "../sideBarMenu/sidebar-menu";
import styles from "./main-layout.module.css";
import { signIn, signOut, useSession } from "next-auth/react";
import SessionProviderWrapper from "@/utils/sessionProviderWrapper";
import { useRouter } from "next/router";
import { SetDynamicRoute } from "@/utils/setDynamicRoute";
import TopBar from "@/components/mobile/topBar/topBar";
import NavbarMenu from "@/components/mobile/navbarMenu/navbarMenu";
import useScreenSize from "@/_core/utils/useScreenSize";
import { getDeviceHeight } from "@/_core/utils/heightDetaction";

interface MainLayoutProps {
     children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
     const [openSidebar, setOpenSideBar] = useState(true);
     const { data: session, status } = useSession();
     const screenSize = useScreenSize();
     const nowTimeStamp = Math.floor(Date.now() / 1000);
     const deviceHeight = getDeviceHeight();

     return screenSize === "large" || screenSize === "xMedium" ? (
          <div style={{ height: deviceHeight - 14 }} className={styles.layoutContainer}>
               {/*  <SetDynamicRoute /> */}
               {session && <SidebarMenu openSidebar={openSidebar} />}
               <div className={openSidebar ? styles.mainContainer : styles.mainContainerBeforLogin}>
                    <MainNavbar openSidebar={openSidebar} setOpenSideBar={setOpenSideBar} />

                    <main className={styles.mainWrapper}>{children}</main>
               </div>
          </div>
     ) : (
          <div className={styles.resLayoutContainer}>
               <TopBar />
               <main className={styles.resMainWrapper}>{children}</main>
               <NavbarMenu />
          </div>
     );
};

export default MainLayout;
