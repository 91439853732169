import Image from "next/image";
import styles from "./membership.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
	MembershipType,
	UserMembershipType,
} from "@/_core/models/userPaymentsTYpe";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { getMmbership } from "@/_core/services/membershipService";
import { getUserMembership } from "@/_core/services/userService";
import { getCoinpaysToken } from "@/_core/services/coinpaysService";

interface MembershipPlanCardProps {
	plan: MembershipType;
	isActive: boolean;
	onSelect: () => void;
	screenSize: string;
	t: (key: string) => string;
}

const MembershipPlanCard = ({
	screenSize,
	plan,
	isActive,
	onSelect,
	t,
}: MembershipPlanCardProps) => (
	<div
		className={
			screenSize == "large" ? styles.plansListCard : styles.resPlansListCard
		}
	>
		<div className={styles.plansListCardHead}>
			<h3>{plan.name}</h3>
		</div>
		<div className={styles.plansListCardContent}>
			<div className={styles.plansListCardContentPrices}>
				<h1 className={styles.blueItems}>${plan.price}</h1>
				<p className={styles.blueItems}>
					<b className={styles.realPrice}>${plan.basePrice}</b> | {plan.period}{" "}
					{t("text.memberships.days")}
				</p>
			</div>
			<ul className={styles.cardListContent}>
				<li>
					{t("text.memberships.crypto_exchange")}:{" "}
					<span>{plan.attributes.max_accounts}</span>
				</li>
				<li>
					{t("text.memberships.transaction_limit")}:{" "}
					<span>{plan.attributes.transaction_limit}</span>
				</li>
				<li>
					{t("text.memberships.real_time_tracking")}:{" "}
					<span>
						{plan.attributes.realtime_tracking
							? t("text.memberships.YES")
							: t("text.memberships.NO")}
					</span>
				</li>
				<li>
					{t("text.memberships.advanced_reports")}:{" "}
					<span>
						{plan.attributes.advanced_reports
							? t("text.memberships.YES")
							: t("text.memberships.NO")}
					</span>
				</li>
				<li>
					{t("text.memberships.commission_view")}:{" "}
					<span>
						{plan.attributes.commissions
							? t("text.memberships.YES")
							: t("text.memberships.NO")}
					</span>
				</li>
				<li>
					{t("text.memberships.arbitrage_access")}:{" "}
					<span>
						{plan.attributes.arbitrage_access
							? t("text.memberships.YES")
							: t("text.memberships.NO")}
					</span>
				</li>
				<li>
					{t("text.memberships.manual_sync")}:{" "}
					<span>{plan.attributes.manual_sync}</span> times/day
				</li>
			</ul>
			<button
				className={isActive ? "secondaryButton" : "primaryButton"}
				onClick={onSelect}
			>
				{isActive
					? t("text.memberships.selected_plan")
					: t("text.memberships.choose_plan")}
			</button>
		</div>
	</div>
);

const ProfileMembership = () => {
	const [monthlyMemberships, setMonthlyMemberships] = useState<
		MembershipType[] | null
	>(null);
	const [yearlyMemberships, setYearlyMemberships] = useState<
		MembershipType[] | null
	>(null);
	const [userMembership, setUserMembership] =
		useState<UserMembershipType | null>(null);
	const [isMonthly, setIsMonthly] = useState(true);
	const [selectedPlan, setSelectedPlan] = useState<MembershipType | null>(null);
	const [token, setToken] = useState<string>("");
	const { data: session } = useSession();
	const { t } = useTranslation("atwallet");
	const router = useRouter();
	const [screenSize, setScreenSize] = useState<string>("");

	useEffect(() => {
		function handleResize() {
			const width = window.innerWidth;
			if (width <= 600) {
				setScreenSize("small");
			} else if (width >= 600 && width <= 1199) {
				setScreenSize("medium");
			} else {
				setScreenSize("large");
			}
		}

		handleResize(); // Call it once to set the initial screen size

		// Attach the event listener
		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Fetch available memberships
	useEffect(() => {
		const fetchMemberships = async () => {
			if (session?.access_token) {
				try {
					const response = await getMmbership(session.access_token);
					setMonthlyMemberships(response.monthly);
					setYearlyMemberships(response.yearly);
				} catch (error) {
					console.error("Failed to fetch memberships:", error);
				}
			}
		};
		fetchMemberships();
	}, [session]);

	// Fetch user's current membership
	useEffect(() => {
		const fetchUserMembership = async () => {
			if (session?.access_token) {
				try {
					const response = await getUserMembership(session.access_token);
					setUserMembership(response);
				} catch (error) {
					console.error("Failed to fetch user membership:", error);
				}
			}
		};
		fetchUserMembership();
	}, [session]);

	// Fetch payment token for selected plan
	useEffect(() => {
		const fetchToken = async () => {
			if (session?.access_token && selectedPlan?.id) {
				try {
					const response = await getCoinpaysToken(
						session.access_token,
						selectedPlan.id,
					);
					setToken(response.token);
				} catch (error) {
					console.error("Failed to fetch payment token:", error);
				}
			}
		};
		fetchToken();
	}, [session, selectedPlan]);

	const handlePlanSelect = (plan: MembershipType) => {
		setSelectedPlan(plan);
		if (token) {
			router.push(`https://app.coinpays.io/payment/${token}`);
		}
	};

	const memberships = isMonthly ? monthlyMemberships : yearlyMemberships;

	return (
		<div
			className={
				screenSize == "large" ? styles.pageContainer : styles.resPageContainer
			}
		>
			{/* Active Plan Section */}
			<div
				className={
					screenSize == "large"
						? styles.activePlanContainer
						: styles.resActivePlanContainer
				}
			>
				<div className={styles.activePlanLeft}>
					<Image
						src="/images/atwallets_pro_logo.svg"
						width={190}
						height={32}
						alt="Atwallets Pro Logo"
					/>
					{screenSize == "large" ? (
						<h2>{t("text.memberships.adv_first_text")}</h2>
					) : (
						<h3>{t("text.memberships.adv_first_text")}</h3>
					)}
					<p
						className={
							screenSize !== "large" ? styles.activePlanLeftParagrap : ""
						}
					>
						{t("text.memberships.adv_second_text")}
					</p>
				</div>
				<div
					className={
						screenSize == "large"
							? styles.activePlanRight
							: styles.resActivePlanRight
					}
				>
					{userMembership ? (
						<>
							<div
								className={
									screenSize == "large"
										? styles.activePlanRightHead
										: styles.resActivePlanRightHead
								}
							>
								<div className={styles.activePlanRightHeadItem}>
									<span>Active Plan</span>
									<h3>{userMembership.membership.name}</h3>
								</div>
								<div className={styles.activePlanRightHeadItem}>
									<span>{t("text.memberships.end_date")}</span>
									<h3>
										{new Date(userMembership.expirationAt).toLocaleDateString()}
									</h3>
								</div>
							</div>
							<ul
								className={
									screenSize == "large"
										? styles.activePlanList
										: styles.resActivePlanList
								}
							>
								<li>
									{t("text.memberships.access_results_for")}{" "}
									{userMembership.membership.period}{" "}
									{t("text.memberships.days")}
								</li>
								<li>
									{t("text.memberships.connect")}{" "}
									{userMembership.membership.attributes.max_accounts}{" "}
									{t("text.memberships.crypto_exchange_accounts")}
								</li>
								<li>
									{userMembership.membership.attributes.advanced_reports
										? t("text.memberships.access_to")
										: t("text.memberships.no_access_to")}{" "}
									{t("text.memberships.advanced_reports")}
								</li>
								<li>
									{userMembership.membership.attributes.commissions
										? t("text.memberships.access_to")
										: t("text.memberships.no_access_to")}{" "}
									{t("text.memberships.commission_view")}
								</li>
							</ul>
						</>
					) : (
						<p>{t("text.memberships.no_active_plan")}</p>
					)}
				</div>
			</div>

			{/* Membership Plans Section */}
			<div className={styles.plansListContainer}>
				<h1>
					<b className={styles.blueItems}>{t("text.memberships.flexible")} </b>{" "}
					{t("text.memberships.pricing_plans")}
				</h1>
				<h4>{t("text.memberships.choose_a_plan")}</h4>
				<div className={styles.planTypechanger}>
					<button
						className={isMonthly ? styles.active : styles.notActive}
						onClick={() => setIsMonthly(true)}
					>
						{t("text.memberships.monthly")}
					</button>
					<button
						className={!isMonthly ? styles.active : styles.notActive}
						onClick={() => setIsMonthly(false)}
					>
						{t("text.memberships.yearly")}
					</button>
				</div>
				<div
					className={
						screenSize == "large"
							? styles.plansListWrapper
							: styles.resPlansListWrapper
					}
				>
					{memberships?.map((plan) => (
						<MembershipPlanCard
							t={t}
							screenSize={screenSize}
							key={plan.id}
							plan={plan}
							isActive={userMembership?.membership.id === plan.id}
							onSelect={() => handlePlanSelect(plan)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileMembership;
