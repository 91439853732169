import PasswordInput from "@/components/base/inputs/password/password-input";
import styles from "./profileSettings.module.css";
import { useEffect, useState } from "react";
import PasswordWithProgressbar from "@/components/base/inputs/password/password-with-progresbar";
import { signOut, useSession } from "next-auth/react";
import { changeAccountPassword } from "@/_core/services/userService";
import { useTranslation } from "react-i18next";

const ProfileSettings = () => {
     const [oldPassword, setOldPassword] = useState<string>("");
     const [newPassword, setNewPassword] = useState<string>("");
     const { data: session, update } = useSession();
     const { t } = useTranslation("atwallet");
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);
     async function keycloakSessionLogOut() {
          try {
               await fetch(`/api/auth/logout`, { method: "GET" });
          } catch (err) {
               console.error(err);
          }
     }

     const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setOldPassword(e.target.value);
     };
     const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setNewPassword(e.target.value);
     };

     const changeHandler = async () => {
          // Ensure session.access_token exists and is a string
          const accessToken = session?.access_token ?? "";
          if (accessToken && oldPassword && newPassword) {
               try {
                    const response = await changeAccountPassword(
                         accessToken, // Use the accessToken safely
                         oldPassword,
                         newPassword
                    );
                    if (response === "OK" || response.ok) {
                         await keycloakSessionLogOut();
                         signOut({ callbackUrl: "/" });
                    }
               } catch (error) {
                    console.error("Failed to update user info", error);
               }
          } else {
               console.error("No access token or user data available");
          }
     };

     return (
          <div className={screenSize === "large" ? styles.main_container : styles.resMain_container}>
               {screenSize === "large" ? (
                    <div className={styles.head_container}>
                         <div className={styles.head_text}>
                              <h1>{t("text.account.settings")}</h1>
                              <p>{t("text.account.settings_message")}</p>
                         </div>
                         <button onClick={changeHandler} className="primaryButton">
                              {t("text.buttons.save-changes")}
                         </button>
                    </div>
               ) : (
                    <div className={styles.resHead_container}>
                         <div className={styles.resHead_text}>
                              <h2>{t("text.account.settings")}</h2>
                              <button onClick={changeHandler} className="primaryButton">
                                   {t("text.buttons.save-changes")}
                              </button>
                         </div>
                         <p>{t("text.account.settings_message")}</p>
                    </div>
               )}
               <div className="primaryVerticalLine"></div>

               <div className={screenSize === "large" ? styles.profile_informations : styles.resProfile_informations}>
                    <div className={screenSize === "large" ? styles.profile_personal_informations : styles.resProfile_personal_informations}>
                         <PasswordInput inputType="password" value={oldPassword} onChange={handleOldPasswordChange} name={t("text.inputs.old_password")} />
                         <PasswordInput inputType="password" value={newPassword} onChange={handleNewPasswordChange} name={t("text.inputs.new_password")} />

                         {/*  <h3>Notifications</h3> */}
                    </div>
                    <div className={styles.profile_contact_informations}>{/* <h3>2 Factor Auth</h3> */}</div>
               </div>
          </div>
     );
};

export default ProfileSettings;
