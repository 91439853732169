import { useSession } from "next-auth/react";
import styles from "./sideMenu.module.css";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Link from "next/link";
import ProfileGeneral from "@/components/account-settings/general";
import ProfileSettings from "@/components/account-settings/settings";
import ProfileWallets from "@/components/account-settings/wallets";
import ProfilePayments from "@/components/account-settings/payments";
import ProfileMehmership from "@/components/account-settings/mehmership";
import { useState } from "react";
import { UserInfoType } from "@/_core/models/userType";
interface Props {
     openSideMenu: boolean;
     setOpenSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
type ListType = {
     _id: number | null;
     title: string;
     icon: string;
     url: string;
};

const SideMenu: React.FC<Props> = ({ openSideMenu, setOpenSideMenu }) => {
     const { data: session, status } = useSession();
     const { t } = useTranslation("atwallet");
     const router = useRouter();
     const { section } = router.query;
     const listItems = [
          { _id: 1, title: t("text.account.general"), url: "general", icon: "person" },
          { _id: 3, title: t("text.account.settings"), url: "settings", icon: "manage_accounts" },
          { _id: 4, title: t("text.account.wallets"), url: "wallets", icon: "account_balance_wallet" },
          { _id: 5, title: t("text.account.membership"), url: "membership", icon: "card_membership" },
          { _id: 6, title: t("text.account.payments"), url: "payments", icon: "payments" },
     ];

     const [selectedOption, setSelectedOption] = useState<ListType>(listItems[0]);
     const [user, setUser] = useState<UserInfoType | null>(null);

     const sidebarList: ListType[] = [
          {
               _id: 7,
               title: t("text.sidebar.orders"),
               icon: "list_alt",
               url: "/orders",
          },

          {
               _id: 8,
               title: t("text.sidebar.trade"),
               icon: "query_stats",
               url: "/trade",
          },
     ];
     const renderComponent = () => {
          switch (section) {
               case "general":
                    return <ProfileGeneral user={user} setUser={setUser} />;
               case "settings":
                    return <ProfileSettings />;
               case "wallets":
                    return <ProfileWallets />;
               case "membership":
                    return <ProfileMehmership />;
               case "payments":
                    return <ProfilePayments />;
               default:
                    return <ProfileGeneral user={user} setUser={setUser} />;
          }
     };
     return (
          <div className={styles.modal_background} onClick={() => setOpenSideMenu(!openSideMenu)}>
               <div className={styles.resModal_container}>
                    <div className={styles.resModal_wrapper}>
                         <div className={styles.profileOwner_Container}>
                              <div className={styles.profile_owner_text}>
                                   {session?.user?.firstname && session?.user?.lastname ? `${session?.user?.firstname[0]}${session?.user?.lastname[0]}` : "N/A"}
                              </div>
                              <div>
                                   <p>{t("text.welcome")}</p>
                                   <h3>{session?.user?.firstname + " " + session?.user?.lastname} </h3>
                              </div>
                         </div>
                         <ul className={styles.listWrapper}>
                              {sidebarList.map((item) => (
                                   <li key={item._id} className={styles.listOptions}>
                                        <Link href={item.url} className={router.pathname === item.url ? styles.listOptionSelected : styles.listOption}>
                                             <span className="material-icons">{item.icon}</span>
                                             <p>{item.title}</p>
                                        </Link>
                                   </li>
                              ))}
                              {listItems.map((item) => (
                                   <li key={item._id} className={styles.listOptions}>
                                        <Link
                                             href={{
                                                  pathname: "/account-settings/[section]",
                                                  query: { section: item.url },
                                             }}
                                             className={section === item.url ? styles.listOptionSelected : styles.listOption}
                                        >
                                             <span className="material-icons">{item.icon}</span>
                                             <p>{item.title}</p>
                                        </Link>
                                   </li>
                              ))}
                         </ul>
                    </div>
               </div>
          </div>
     );
};

export default SideMenu;
