import Image from "next/image";
import Link from "next/link";
import { Router } from "next/router";
import { useEffect, useState } from "react";

const getInitialTheme = () => {
  const router = Router;
  if (typeof window !== "undefined") {
    return localStorage.getItem("theme") || "light";
  }
  return "light";
};

const BrandLogo = () => {
  const [theme, setTheme] = useState(getInitialTheme);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentTheme = localStorage.getItem("theme") || "light";
      setTheme(currentTheme);

      const themeChangeHandler = () => {
        const updatedTheme = localStorage.getItem("theme") || "light";
        setTheme(updatedTheme);
      };

      window.addEventListener("storage", themeChangeHandler);

      return () => {
        window.removeEventListener("storage", themeChangeHandler);
      };
    }
  }, []);

  useEffect(() => {
    const themeChangeHandler = () => {
      const updatedTheme = localStorage.getItem("theme") || "light";
      setTheme(updatedTheme);
    };

    window.addEventListener("theme-change", themeChangeHandler);

    return () => {
      window.removeEventListener("theme-change", themeChangeHandler);
    };
  }, []);

  return (
    <Link href="/">
      {theme === "dark" ? (
        <Image
          alt="AtWallet Brand Logo"
          src="/images/atWallet_Logo_light.svg"
          width={160}
          height={40}
        />
      ) : (
        <Image
          alt="AtWallet Brand Logo"
          src="/images/atWallet_Logo_dark.svg"
          width={160}
          height={40}
        />
      )}
    </Link>
  );
};

export default BrandLogo;
