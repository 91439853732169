const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/currencies`;

export async function getCurrency() {
  try {
    // Send a GET request to the currencies endpoint
    const response = await fetch(apiUrl, {
      method: "GET",
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    // Parse and return the JSON response
    const data = await response.json();

    return data;
  } catch (error) {
    // Log or handle the error appropriately
    console.error("Error fetching currencies:", error);
    throw error;
  }
}
