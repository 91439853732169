import { useEffect, useState } from "react";
import Select, { StylesConfig, SingleValue, ActionMeta } from "react-select";
import styles from "./currencyTypeSelector.module.css";
import { getCurrency } from "@/_core/services/currencyService";
import { CurrencyOption } from "@/_core/models/currencyType";
import { useTranslation } from "react-i18next";

const customStyle: StylesConfig<CurrencyOption, false> = {
  control: (styles) => ({
    ...styles,
    borderRadius: "5px",
    border: "none",
    backgroundColor: "var(--light_bg-color)",
    height: "42px",
  }),
};

interface Props {
  setChangedCurrency: React.Dispatch<
    React.SetStateAction<CurrencyOption | undefined>
  >;
  userCurrencyId?: CurrencyOption | null;
}

const CurrencyTypeSelectors: React.FC<Props> = ({
  setChangedCurrency,
  userCurrencyId,
}) => {
  const [currencies, setCurrencies] = useState<CurrencyOption[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyOption | null>(null);
  const { t } = useTranslation("atwallet");

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const data = await getCurrency();
        const formattedCurrencies = data.currencies.map((currency: any) => ({
          id: currency.id,
          code: currency.code,
          icon: currency.icon,
          name: currency.name,
          symbol: currency.symbol,
          label: `${currency.name} (${currency.symbol})`, // for display in Select
        }));

        setCurrencies(formattedCurrencies);

        // Set selectedCurrency based on userCurrencyId
        const initialCurrency = formattedCurrencies.find(
          (currency: CurrencyOption) => currency.id === userCurrencyId?.id
        );
        setSelectedCurrency(initialCurrency);
      } catch (error) {
        setError(
          error instanceof Error
            ? error.message
            : "An unexpected error occurred"
        );
      }
    };

    fetchCurrency();
  }, [userCurrencyId]);

  useEffect(() => {
    if (selectedCurrency) {
      setChangedCurrency(selectedCurrency);
    }
  }, [selectedCurrency, setChangedCurrency]);

  return (
    <div className="inputs_container">
      <div>
        <label htmlFor="currency-symbol-select" className="input_label">
          Currency {t("text.inputs.currency")}
        </label>
        <Select
          id="currency-symbol-select"
          options={currencies}
          styles={customStyle}
          onChange={(option) => setSelectedCurrency(option as CurrencyOption)}
          value={selectedCurrency}
          isClearable
        />
        {error && <p className={styles.error_message}>{error}</p>}
      </div>
    </div>
  );
};

export default CurrencyTypeSelectors;
