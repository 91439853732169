const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/coinpays`;

export async function getCoinpaysToken(token: string, membershipId: number) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(`${apiUrl}/token?membershipId=${membershipId}`, {
               method: "GET",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching currencies:", error);
          throw error;
     }
}

export async function coinpaysCallback(body: Record<string, any>) {
     try {
          // Convert the body to URL-encoded format
          const urlEncodedBody = new URLSearchParams(body).toString();

          const response = await fetch(`${apiUrl}/callback`, {
               method: "POST",
               headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
               },
               body: urlEncodedBody, // Send URL-encoded body
          });
     

          // Check if the response is successful
          if (!response.ok) {
               const errorData = await response.json();
               throw new Error(`Failed to create wallet. Status: ${response.status}, Error: ${errorData.message || response.statusText}`);
          }

          // Return the JSON response if the request was successful
          const data = await response;
          return data;
     } catch (error: any) {
          console.error("Error creating the wallet:", error.message);
          throw error;
     }
}
