import React, { useState, useEffect, ForwardedRef } from "react";
import styles from "./password.module.css";

// Define the props interface
interface PasswordInputProps {
  inputType: "password" | "text";
  name?: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Define the PasswordInput component with forwardRef
const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const [type, setType] = useState<string>(props.inputType);
    const [icon, setIcon] = useState<string>("visibility_off");

    const handleToggle = () => {
      if (type === "password") {
        setIcon("visibility");
        setType("text");
      } else {
        setIcon("visibility_off");
        setType("password");
      }
    };

    useEffect(() => {
      setIcon(props.inputType === "password" ? "visibility_off" : "visibility");
    }, [props.inputType]);
    return (
      <div className={styles.inputField}>
        {props.name && <label htmlFor={props.name}>{props.name}</label>}
        <div className={styles.input_wrapper}>
          <input
            className={props.className}
            name={props.name}
            type={type}
            onChange={(e) => {
              props.onChange && props.onChange(e);
            }}
            value={props.value || ""}
            ref={ref}
          />
          <span
            className="material-icons"
            onClick={handleToggle}
            style={{ cursor: "pointer" }}
          >
            {icon}
          </span>
        </div>
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
