// Define the API URL outside of the function so it's reusable
const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/wallets`;

/* GET USER ALL WALLETS API */
export async function getWallets(
     token: string | null,
     offset: number,
     limit: number,
     options?: {
          exchangeId?: number | null;
          coinId?: number | null;
          sort?: string | null;
     }
) {
     try {
          if (token) {
               // Construct the base URL with required parameters
               let url = `${apiUrl}?offset=${offset}&limit=${limit}`;

               // Append optional parameters if they are defined and not null
               if (options) {
                    Object.keys(options).forEach((key) => {
                         const value = options[key as keyof typeof options];
                         if (value !== null && value !== undefined) {
                              url += `&${key}=${value}`;
                         }
                    });
               }
               const response = await fetch(url, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function deleteWallet(token: string, id: number) {
     try {
          if (!token) {
               throw new Error("Access token is missing");
          }

          const response = await fetch(`${apiUrl}/${id}`, {
               method: "DELETE",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          if (!response.ok) {
               throw new Error(`HTTP error! status: ${response.status}`);
          }

          return true; // Return true to indicate success
     } catch (error) {
          console.error("Error deleting the wallet:", error);
          throw error; // Re-throw the error for the caller to handle
     }
}

export async function syncWallet(token: string, id: number) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/${id}/sync`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response;

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
/* CREATE WALLET API */
export async function CreateWallet(
     token: string,
     credentialInfo: Record<string, string>, // Type to define a map of credentials
     name: string,
     exchangeId: number,
     currencyId: number
) {
     try {
          if (token) {
               const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                         Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                         name,
                         exchangeId,
                         currencyId,
                         credentialInfo, // Send the credentialInfo object
                    }),
               });

               // Check if the response is successful
               if (!response.ok) {
                    const errorData = await response.json(); // Extract error details from response
                    throw new Error(`Failed to create wallet. Status: ${response.status}, Error: ${errorData.message || response.statusText}`);
               }

               // Return the JSON response if the request was successful
               const data = await response.json();
               return data;
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error creating the wallet:", error);
          throw error;
     }
}
export async function EditWallet(
     token: string,
     credentialInfo: Record<string, string>, // Type to define a map of credentials
     name: string,
     walletId: number,
     currencyId: number
) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/${walletId}`, {
                    method: "PUT",
                    headers: {
                         "Content-Type": "application/json",
                         Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                         name,
                         credentialInfo,
                         currencyId, // Send the credentialInfo object
                    }),
               });

               // Check if the response is successful
               if (!response.ok) {
                    const errorData = await response.json(); // Extract error details from response
                    throw new Error(`Failed to create wallet. Status: ${response.status}, Error: ${errorData.message || response.statusText}`);
               }

               // Return the JSON response if the request was successful
               const data = await response.json();
               return data;
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error creating the wallet:", error);
          throw error;
     }
}

export async function getWalletBalance(token: string, walletId: number, balanceType: string) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/${walletId}/balances/${balanceType}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
