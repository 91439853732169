const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/memberships`;

export async function getMmbership(token: string) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
