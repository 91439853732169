import ThemeToggle from "@/components/base/themeToggle/theme-toggle";
import LangButton from "@/components/base/dropdown/lang-dropdown";
import BrandLogo from "@/components/base/brandLogo/brandLogo";
import AuthStatus from "@/components/auth/authStatus";
import { useTranslation } from "react-i18next";
import styles from "./main-navbar.module.css";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";

type Props = {
     setOpenSideBar: (open: boolean) => void;
     openSidebar: boolean;
};

const MainNavbar: React.FC<Props> = ({ setOpenSideBar, openSidebar }) => {
     const { data: session } = useSession();
     const { t } = useTranslation("atwallet");

     return (
          <div className={styles.navbarContainer}>
               {session && (
                    <button className={styles.menuIcon} onClick={() => setOpenSideBar(!openSidebar)}>
                         <span className="material-icons">menu</span>
                    </button>
               )}
               {session && (
                    <div className={styles.navbarWrapper}>
                         <div className={styles.navbarWelcome}>
                              <p>{t("text.welcome")}</p>
                              <h3>
                                   {session?.user?.firstname} {session?.user?.lastname}
                              </h3>
                         </div>
                         <div className={styles.navbarActions}>
                              <ThemeToggle />
                              <LangButton />
                              <div className="primaryHr"></div>
                              <AuthStatus />
                         </div>
                    </div>
               )}
          </div>
     );
};

export default MainNavbar;
