import ThemeToggle from "@/components/base/themeToggle/theme-toggle";
import styles from "./topBar.module.css";
import BrandLogo from "@/components/base/brandLogo/brandLogo";
import Link from "next/link";
import { useState } from "react";
import SideMenu from "../sideMenu/sideMenu";

const TopBar = () => {
     const [openSideMenu, setOpenSideMenu] = useState<boolean>(false);
     return (
          <div className={styles.topBarContainer}>
               <div className={styles.leftActions}>
                    <button onClick={() => setOpenSideMenu(!openSideMenu)} className="material-icons">
                         menu
                    </button>
                    {/*  <Link className={styles.accountSettings} href="/account-settings">
                         <span className="material-icons">manage_accounts</span>
                    </Link> */}
               </div>

               <BrandLogo />
               <ThemeToggle />
               {openSideMenu && <SideMenu openSideMenu={openSideMenu} setOpenSideMenu={setOpenSideMenu} />}
          </div>
     );
};

export default TopBar;
