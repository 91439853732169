import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";
import classes from "./lang-dropdown.module.css";
import Image from "next/image";
import { getRegions } from "@/_core/services/regionsService";
import { updateUserInfo } from "@/_core/services/userService"; // Import your service
import { useSession } from "next-auth/react";
import { RegionsType } from "@/_core/models/regionsType";
import { UserInfoType } from "@/_core/models/userType";

const LangButton: React.FC = () => {
     const { locale, locales, push } = useRouter();
     const router = useRouter();
     const [openDropdown, setOpenDropdown] = useState<boolean>(false);
     const [selectedLocale, setSelectedLocale] = useState<RegionsType | null>(null);
     const dropdownRef = useRef<HTMLDivElement>(null);
     const { data: session, update } = useSession();
     const [regions, setRegions] = useState<RegionsType[]>([]);
     const [error, setError] = useState<string | null>(null);

     // Fetch regions and set the default locale
     useEffect(() => {
          const fetchRegions = async () => {
               try {
                    const data = await getRegions();
                    setRegions(data.regions);

                    if (session?.user?.region) {
                         const defaultRegion = data.regions.find((region: RegionsType) => region.id === session?.user?.region);
                         if (defaultRegion) {
                              setSelectedLocale(defaultRegion);
                              localStorage.setItem("locale", defaultRegion.locale);
                         }
                    }
               } catch (error) {
                    setError(error instanceof Error ? error.message : "An unexpected error occurred");
               }
          };

          fetchRegions();
     }, [session, setSelectedLocale]);

     const existingUserData = session?.user && session?.user;

     const handleClick = async (region: RegionsType) => {
          push(router.asPath, undefined, { locale: region.locale });
          setSelectedLocale(region);
          setOpenDropdown(false);

          const updatedUserData: Partial<any> = {
               currency: session?.user?.currency.id,
               region: region?.id, // Store only the currency ID
          };

          if (session?.access_token && existingUserData && updatedUserData) {
               try {
                    // Create a payload with only the updated region

                    // Call the updateUserInfo service with the updated data
                    const response = await updateUserInfo(session.access_token, updatedUserData, existingUserData);
                    if (response) {
                         update({ ...session, user: { ...session.user, ...response } });
                    }
               } catch (error) {
                    console.error("Error updating user info:", error);
               }
          }
     };

     const dropdownHandler = () => {
          setOpenDropdown(!openDropdown);
     };

     // Close dropdown if clicking outside
     useEffect(() => {
          const handleClickOutside = (event: MouseEvent) => {
               if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setOpenDropdown(false);
               }
          };

          document.addEventListener("mousedown", handleClickOutside);

          return () => {
               document.removeEventListener("mousedown", handleClickOutside);
          };
     }, []);

     return (
          <div ref={dropdownRef}>
               {selectedLocale && (
                    <div className={classes.dropdownBtn} onClick={dropdownHandler}>
                         <Image className={classes.flagImage} src={selectedLocale.icon} width={24} height={24} alt="flag" />
                         <span className="material-icons">expand_more</span>
                    </div>
               )}

               {openDropdown && regions.length > 0 && (
                    <div className={classes.showDropdown}>
                         {regions.map((region: RegionsType) => (
                              <button className={classes.langBtn} key={region.id} onClick={() => handleClick(region)}>
                                   <Image className={classes.flagImage} src={region.icon} width={24} height={24} alt="flag" />
                                   {region.title}
                              </button>
                         ))}
                    </div>
               )}
          </div>
     );
};

export default LangButton;
