const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/regions`;

export async function getRegions() {
     try {
          const response = await fetch(`${apiUrl}`, {
               method: "GET",
          });

          if (!response.ok) {
               throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          return data; // Return the data to the caller
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
