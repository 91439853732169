"use client";

import { useSession, signIn, signOut } from "next-auth/react";
import { useEffect, useState, useRef } from "react";
import styles from "./authStatus.module.css";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { UserInfoType } from "@/_core/models/userType";

async function keycloakSessionLogOut() {
  try {
    await fetch(`/api/auth/logout`, { method: "GET" });
  } catch (err) {
    console.error(err);
  }
}

declare module "next-auth" {
  interface Session {
    decoded: string;
    access_token?: string;
    refresh_token?: string;
    id_token?: string;
    expires_at?: number;
    expires_in?: number;
    refresh_expires_in: number;
    user?: UserInfoType;
    error?: any;
  }
}
export default function AuthStatus() {
  // Cast session to CustomSession using 'as' assertion
  const { data: session, status } = useSession();

  /*   const session = sessionData as CustomSession; */
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("atwallet");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (status === "loading") {
    return <div className="my-3">Loading...</div>;
  }

  return (
    session && (
      <div ref={dropdownRef} className={styles.authContainer}>
        <button
          className={styles.account_dropdown_container}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {session?.user?.imgUrl ? (
            <Image
              alt={session.user.firstname || "User Avatar"}
              src={session.user.imgUrl}
              width={24}
              height={24}
            />
          ) : (
            <span
              title={session.user ? session.user.firstname : "User Avatar"}
              className="material-icons"
            >
              account_circle
            </span>
          )}
          <span className="material-icons">expand_more</span>
        </button>
        {openDropdown && (
          <div className={styles.account_dropdown}>
            <Link href="/account-settings" className={styles.my_account}>
              {t("text.buttons.my_account")}
            </Link>
            <button
              className={styles.logOut_Button}
              onClick={() => {
                keycloakSessionLogOut().then(() => {
                  signOut({ redirect: false }).then(() => signIn("keycloak"));
                });
              }}
            >
              {t("text.buttons.logout")}
            </button>
          </div>
        )}
      </div>
    )
  );
}
