import { UserInfoType } from "../models/userType";

const apiUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/users/me`;

export async function getUserInfo(token: string) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(apiUrl, {
               method: "GET",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching user:", error);
          throw error;
     }
}
export async function getUserMembership(token: string) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(`${apiUrl}/membership`, {
               method: "GET",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching user:", error);
          throw error;
     }
}
export async function getUserAsserOverview(token: string) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(`${apiUrl}/assets/overview`, {
               method: "GET",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching user:", error);
          throw error;
     }
}
export async function getUserPayments(token: string) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(`${apiUrl}/payments`, {
               method: "GET",
               headers: {
                    Authorization: `Bearer ${token}`,
               },
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching user:", error);
          throw error;
     }
}
export async function changeAccountPassword(token: string, oldPassword: string, newPassword: string) {
     try {
          // Send a GET request to the currencies endpoint
          const response = await fetch(`${apiUrl}/password`, {
               method: "PUT",
               headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Set content type for JSON data
               },
               body: JSON.stringify({
                    oldPassword,
                    newPassword,
               }),
          });

          // Check if the response is successful
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
               return await response.json(); // Handle JSON response
          } else {
               return await response.text(); // Handle text response like "OK"
          }
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error fetching user:", error);
          throw error;
     }
}

export async function updateUserInfo(
     token: string,
     updatedUserData: Partial<UserInfoType>, // Partial means only the updated fields need to be passed
     existingUserData: UserInfoType // The existing user data to compare with
) {

     try {
          // Merge the updatedUserData with the existingUserData
          const mergedData: UserInfoType = {
               ...existingUserData, // Start with all the existing user data
               ...updatedUserData, // Override with any updated values
          };

          // Send a PUT request with the merged data
          const response = await fetch(apiUrl, {
               method: "PUT",
               headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Set content type for JSON data
               },
               body: JSON.stringify(mergedData), // Send the merged data as JSON
          });

          // Check if the response is successful
          if (!response.ok) {
               throw new Error(`Error: ${response.statusText}`);
          }

          // Parse and return the JSON response
          const data = await response.json();

          return data;
     } catch (error) {
          // Log or handle the error appropriately
          console.error("Error updating user info:", error);
          throw error;
     }
}
export const newUpdateUserInfo = async (accessToken: string, updatedData: any) => {
     const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(updatedData),
     });

     if (!response.ok) {
          throw new Error(`Failed to update user info: ${response.statusText}`);
     }

     return response.json();
};
export async function getUserExchanges(
     token: string,
     offset: number,
     limit: number,
     options?: {
          exchangeId?: number | null;
          coinId?: number | null;
          sort?: string | null;
     }
) {
     try {
          if (token) {
               // Construct the base URL with required parameters
               let url = `${apiUrl}/exchanges?offset=${offset}&limit=${limit}`;

               // Append optional parameters if they are defined and not null
               if (options) {
                    Object.keys(options).forEach((key) => {
                         const value = options[key as keyof typeof options];
                         if (value !== null && value !== undefined) {
                              url += `&${key}=${value}`;
                         }
                    });
               }

               const response = await fetch(url, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getUserExchangeDetail(token: string, exchangeId: number) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/coins?exchangeId=${exchangeId}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getUserCoins(
     token: string,
     offset: number,
     limit: number,
     options?: {
          exchangeId?: number | null;
          coinId?: number | null;
          sort?: string | null;
          type?: string | null;
     }
) {
     try {
          if (token) {
               // Construct the base URL with required parameters
               let url = `${apiUrl}/coins?offset=${offset}&limit=${limit}`;

               // Append optional parameters if they are defined and not null
               if (options) {
                    Object.keys(options).forEach((key) => {
                         const value = options[key as keyof typeof options];
                         if (value !== null && value !== undefined) {
                              url += `&${key}=${value}`;
                         }
                    });
               }

               const response = await fetch(url, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getUserCoinDetail(token: string, coinId: number) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/exchanges?coinId=${coinId}`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}

export async function getUserArbitrage(token: string) {
     try {
          if (token) {
               const response = await fetch(`${apiUrl}/arbitrage`, {
                    method: "GET",
                    headers: {
                         Authorization: `Bearer ${token}`,
                    },
               });

               if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
               }

               const data = await response.json();

               return data; // Return the data to the caller
          } else {
               throw new Error("Access token is missing");
          }
     } catch (error) {
          console.error("Error fetching the data:", error);
          throw error; // Throw the error so the caller can handle it
     }
}
