import PrimaryInput from "@/components/base/inputs/primaryInput";
import Image from "next/image";
import Link from "next/link";
import styles from "./exchangeConnecting.module.css";
import { useSession } from "next-auth/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditWallet as EditWalletApi } from "@/_core/services/walletsService"; // Use the EditWallet API function
import { WalletType } from "@/_core/models/walletType";
import lottie from "lottie-web";

interface Props {
     selectedWallet: WalletType;
     setSelectedWallet: React.Dispatch<React.SetStateAction<WalletType | null>>;
     editModal: boolean;
     setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
     setWalletRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CredentialInfoType {
     apiKey: string;
     password: string;
     secretKey: string;
}

const EditWallet: React.FC<Props> = ({ selectedWallet, setSelectedWallet, editModal, setEditModal, setWalletRefetch }) => {
     const { data: session } = useSession();
     const { t } = useTranslation("wallet");
     const [walletName, setWalletName] = useState<string>(selectedWallet.name); // Pre-fill wallet name
     const [credentialInfo, setCredentialInfo] = useState<Record<string, string>>(
          selectedWallet?.credentialInfo
               ? Object.fromEntries(
                      Object.entries(selectedWallet.credentialInfo).map(
                           ([key, value]) => [key, String(value)] // Ensure all values are strings
                      )
                 )
               : {}
     );

     const [privacyAgreement, setPrivacyAgreement] = useState<boolean>(false);
     const [error, setError] = useState<string>("");
     const [loading, setLoading] = useState<boolean>(false);
     const [success, setSuccess] = useState<boolean>(false);

     const animationContainer = useRef<HTMLDivElement | null>(null);
     const successContainer = useRef<HTMLDivElement | null>(null);

     useEffect(() => {
          let animationInstance: any;

          if (loading && animationContainer.current) {
               animationInstance = lottie.loadAnimation({
                    container: animationContainer.current,
                    renderer: "svg",
                    loop: true,
                    autoplay: true,
                    path: "/animations/animation.json",
               });
          }

          return () => {
               if (animationInstance) {
                    animationInstance.destroy();
               }
          };
     }, [loading]);

     useEffect(() => {
          let successInstance: any;

          if (success && successContainer.current) {
               successInstance = lottie.loadAnimation({
                    container: successContainer.current,
                    renderer: "svg",
                    loop: false,
                    autoplay: true,
                    path: "/animations/successfull.json",
               });
          }

          return () => {
               if (successInstance) {
                    successInstance.destroy();
                    setWalletRefetch(true);
               }
          };
     }, [success, setWalletRefetch]);

     // Function to handle credential input changes
     const handleCredentialChange = (key: string, value: string) => {
          setCredentialInfo((prev) => ({
               ...prev,
               [key]: value,
          }));
     };
     // Handle "Save Changes" button click
     const handleEdit = () => {
          if (!walletName) {
               setError("Please enter a wallet name.");
               return;
          }

          if (Object.keys(credentialInfo).length !== Object.keys(selectedWallet.credentialInfo).length) {
               setError("Please fill in all credential fields.");
               return;
          }

          setError("");
          setLoading(true);

          if (session?.access_token) {
               EditWalletApi(
                    session.access_token,
                    credentialInfo,
                    walletName,
                    selectedWallet.id, // Use selectedWallet id for editing
                    selectedWallet.currencyId
               )
                    .then(() => {
                         setLoading(false);
                         setSuccess(true);
                    })
                    .catch((err) => {
                         setLoading(false);
                         setError("Failed to edit wallet. Please try again.");
                    });
          }
     };
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);
     return (
          <div className={styles.modal_background}>
               <div className={screenSize == "large" ? styles.modal_wrapper : styles.resModal_wrapper}>
                    {loading && (
                         <div className={styles.loading_container}>
                              <div ref={animationContainer}></div>
                              <h4>Please wait while we update your wallet.</h4>
                              <Image src="/images/loading.svg" alt="spinner" width={56} height={56} />
                         </div>
                    )}

                    {!loading && !success && (
                         <div className={styles.selected_wallet_container}>
                              <Image src={selectedWallet.exchange.exchangeLogo} width={130} height={32} alt={selectedWallet.exchange.name} />

                              {/*   <p className={styles.wallet_description}>
              {t(`exchangesNote.${selectedWallet.name}`)}
            </p> */}
                              <div className="horizontal_line"></div>
                              <div className={styles.inputs_container}>
                                   <PrimaryInput
                                        type="text"
                                        icon="api_black"
                                        inputName="Wallet Name"
                                        placeholder="Your Wallet Name"
                                        value={walletName} // Pre-fill wallet name
                                        onChange={(e: any) => setWalletName(e.target.value)}
                                   />

                                   {/* Dynamically generated credentialInfo inputs */}
                                   {Object.entries(credentialInfo).map(([key, value]) => (
                                        <PrimaryInput
                                             key={key}
                                             type="text"
                                             icon="api_black"
                                             inputName={key.charAt(0).toUpperCase() + key.slice(1)}
                                             placeholder={`Your ${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                             value={value || ""} // Use value from state or empty string
                                             onChange={(e: any) => handleCredentialChange(key, e.target.value)}
                                        />
                                   ))}
                              </div>

                              {error && <p className={styles.error_message}>{error}</p>}

                              <div className={styles.modal_actions}>
                                   <button
                                        className="secondaryButton"
                                        onClick={() => {
                                             setEditModal(!editModal);
                                             setSelectedWallet(null);
                                        }}
                                   >
                                        Cancel
                                   </button>
                                   <button className="primaryButton" onClick={handleEdit}>
                                        Save Changes
                                   </button>
                              </div>
                         </div>
                    )}

                    {success && (
                         <div className={styles.success_container}>
                              <div ref={successContainer}></div>

                              <h4>Wallet updated successfully!</h4>
                              <h3> Your wallet has been edited.</h3>
                              <button
                                   className="primaryButton"
                                   onClick={() => {
                                        setEditModal(!editModal);
                                        setSelectedWallet(null);
                                   }}
                              >
                                   Done
                              </button>
                         </div>
                    )}
               </div>
          </div>
     );
};

export default EditWallet;
