import { useCallback, useEffect, useState } from "react";
import styles from "./profileWallets.module.css";
import { WalletType } from "@/_core/models/walletType";
import { useSession } from "next-auth/react";
import { getWallets } from "@/_core/services/walletsService";
import Image from "next/image";
import EditWallet from "@/components/addPortfolioModal/exchangeConnecting/editWallet";
import { useTranslation } from "react-i18next";
import DeleteWalletModal from "@/components/alertsComponents/deleteWalletModal/deletWalletModal";

const ProfileWallets = () => {
     const { data: session, status } = useSession();
     const [wallets, setWallets] = useState<WalletType[] | null>([]);
     const [error, setError] = useState<string | null>(null);
     const [editModal, setEditModal] = useState<boolean>(false);
     const [walletRefetch, setWalletRefetch] = useState<boolean>(false);
     const [selectedWallet, setSelectedWallet] = useState<WalletType | null>(null);
     const { t } = useTranslation("atwallet");
     const [deleteModal, setDeleteModal] = useState<boolean>(false);
     // Function to fetch wallets
     const fetchWallets = useCallback(async () => {
          if (session?.access_token) {
               try {
                    const data = await getWallets(session.access_token, 0, 150);
                    setWallets(data.wallets);
               } catch (error) {
                    console.error("Error fetching wallets:", error);
                    setError(error instanceof Error ? error.message : "An unexpected error occurred");
               }
          }
     }, [session]);

     // Fetch wallets when component mounts or walletRefetch changes
     useEffect(() => {
          fetchWallets();
     }, [fetchWallets, session, walletRefetch]);

     const walletEditHandler = (wallet: WalletType) => {
          setSelectedWallet(wallet);
          setEditModal(true);
     };
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);

     return (
          <div className={screenSize == "large" ? styles.main_container : styles.res_main_container}>
               <div>
                    <h1>{t("text.account.wallets")}</h1>
                    <div className={screenSize == "large" ? styles.wallet_list : styles.res_wallet_list}>
                         {wallets &&
                              wallets.map((wallet) => {
                                   return (
                                        <div key={wallet.id} className={styles.wallet_list_table}>
                                             <div className={screenSize == "large" ? styles.table_leftSide : styles.resTable_leftSide}>
                                                  <Image src={wallet.exchange.exchangeLogo} width={100} height={24} alt={wallet.exchange.exchangeIcon} />
                                                  {screenSize == "large" && <div className="primaryHr"></div>}
                                                  <div className={styles.wallet_name}>
                                                       <span>{t("text.account.name")}</span>
                                                       <h3>{wallet.name}</h3>
                                                  </div>
                                             </div>
                                             <div className={styles.actionContainer}>
                                                  <button onClick={() => walletEditHandler(wallet)} className={screenSize == "large" ? "primaryButton" : styles.editButtons}>
                                                       <span className="material-icons">edit</span>
                                                       {screenSize == "large" && t("text.buttons.edit")}
                                                  </button>
                                                  {/*   <button onClick={() => walletDeleteHandler(wallet)} className="deleteButton">
                                                       <span className="material-icons">delete</span>
                                                  </button> */}
                                                  <button
                                                       onClick={() => {
                                                            setSelectedWallet(wallet);
                                                            setDeleteModal(!deleteModal);
                                                       }}
                                                       className="deleteButton"
                                                  >
                                                       <span className="material-icons">delete</span>
                                                  </button>
                                             </div>
                                        </div>
                                   );
                              })}
                    </div>
               </div>
               {editModal && selectedWallet && (
                    <EditWallet setWalletRefetch={setWalletRefetch} setSelectedWallet={setSelectedWallet} selectedWallet={selectedWallet} editModal={editModal} setEditModal={setEditModal} />
               )}
               {deleteModal && (
                    <DeleteWalletModal
                         walletRefetch={walletRefetch}
                         setWalletRefetch={setWalletRefetch}
                         deleteModal={deleteModal}
                         setDeleteModal={setDeleteModal}
                         selectedWallet={selectedWallet}
                         setSelectedWallet={setSelectedWallet}
                    />
               )}
          </div>
     );
};

export default ProfileWallets;
