import styles from "./primary-inputs.module.css";

type Props = {
  placeholder: string;
  type: string;
  icon: string;
  inputName: string;
  onChange: any | null; // Flexible type, can be changed based on your needs
  value: string;
};

const PrimaryInput: React.FC<Props> = ({
  placeholder,
  type,
  icon,
  inputName,
  onChange,
  value,
}) => {
  return (
    <div className={styles.input_container}>
      <label htmlFor="">{inputName}</label>
      <div className={styles.input_wrapper}>
        <span className="material-icons">{icon}</span>
        <input
          type={type}
          placeholder={placeholder}
          value={value && value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default PrimaryInput;
