import { Country } from "country-state-city";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { StylesConfig, SingleValue, ActionMeta } from "react-select";

interface CountryOption {
  label: string;
  isoCode: string;
  name: string;
}

interface CountrySelectProps {
  existingCountry: string; // Pass the existing country name as a prop
  setCountry: React.Dispatch<React.SetStateAction<string>>; // Function to update country in the parent component
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  existingCountry,
  setCountry,
}) => {
  const customStyle: StylesConfig<CountryOption, false> = {
    control: (styles) => ({
      ...styles,
      borderRadius: "5px",
      border: "none",
      backgroundColor: "var(--light_bg-color)",
      height: "42px",
    }),
  };
  const { t } = useTranslation("atwallet");
  const [countryValue, setCountryValue] = useState<CountryOption | undefined>();

  // Use useMemo to memoize countryOptions
  const countryOptions = useMemo(() => {
    const countries = Country.getAllCountries();
    return countries.map((country) => ({
      label: country.name,
      isoCode: country.isoCode,
      name: country.name,
    }));
  }, []); // Empty dependency array ensures it's only created once

  // Set the default country value based on the existing country
  useEffect(() => {
    if (existingCountry) {
      const defaultCountry = countryOptions.find(
        (option) => option.name === existingCountry
      );
      setCountryValue(defaultCountry); // Set the existing country as the selected value
    }
  }, [existingCountry, countryOptions]); // Dependency on existingCountry only

  const handleCountryChange = (
    newValue: SingleValue<CountryOption>,
    actionMeta: ActionMeta<CountryOption>
  ) => {
    if (newValue) {
      setCountryValue(newValue); // Update local state
      setCountry(newValue.name); // Call setCountry to update parent state
    } else {
      setCountry(""); // Clear country if none is selected
    }
  };

  return (
    <div>
      <label htmlFor="multiselect" className="input_label">
        {t("text.inputs.country")}
      </label>
      <Select
        options={countryOptions}
        styles={customStyle}
        onChange={handleCountryChange}
        value={countryValue || null} // Set the current selected country value
        isClearable
      />
    </div>
  );
};

export default CountrySelect;
