import { useState, useEffect } from "react";

const useScreenSize = () => {
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width > 600 && width <= 1000) {
                    setScreenSize("medium");
               } else if (width > 980 && width <= 1599) {
                    setScreenSize("xMedium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call once to initialize

          window.addEventListener("resize", handleResize);

          return () => window.removeEventListener("resize", handleResize); // Cleanup
     }, []);

     return screenSize;
};

export default useScreenSize;
