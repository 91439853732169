import { useRouter } from "next/router";
import styles from "./navBar.module.css";
import Link from "next/link";

const NavbarMenu = () => {
     const router = useRouter();
     return (
          <ul className={styles.navBarContainer}>
               <li className={router.pathname === "/" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/">
                         <span className="material-icons">dashboard</span>
                    </Link>
                    {router.pathname === "/" ? "Home" : ""}
               </li>
               <li className={router.pathname === "/coins" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/coins">
                         <span className="material-icons"> donut_large</span>
                    </Link>
                    {router.pathname === "/coins" ? "Coins" : ""}
               </li>
               <li className={router.pathname === "/wallets" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/wallets">
                         <span className="material-icons">account_balance_wallet</span>
                    </Link>
                    {router.pathname === "/wallets" ? "Wallets" : ""}
               </li>
               <li className={router.pathname === "/exchanges" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/exchanges">
                         <span className="material-icons"> currency_exchange</span>
                    </Link>
                    {router.pathname === "/exchanges" ? "Exchanges" : ""}
               </li>
               <li className={router.pathname === "/arbitrage" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/arbitrage">
                         <span className="material-icons"> price_change</span>
                    </Link>
                    {router.pathname === "/arbitrage" ? "Arbitrages" : ""}
               </li>
               {/*   <li className={router.pathname === "/orders" ? styles.navBarItemsActive : styles.navBarItemsNotActive}>
                    <Link href="/orders">
                         <span className="material-icons"> price_change</span>
                    </Link>
                    {router.pathname === "/orders" ? "Orders" : ""}
               </li> */}
          </ul>
     );
};

export default NavbarMenu;
