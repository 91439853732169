import { useEffect, useState } from "react";
import styles from "./payments.module.css";
import { getUserPayments } from "@/_core/services/userService";
import { useSession } from "next-auth/react";
import { UserPaymentsType } from "@/_core/models/userPaymentsTYpe";
import NoDataResult from "@/components/noResults/noDataResult";
import { useTranslation } from "react-i18next";

const ProfilePayments = () => {
	const [userPayments, setUserPayments] = useState<UserPaymentsType | null>(
		null,
	);
	const { t } = useTranslation("atwallet");

	const { data: session } = useSession();
	const [screenSize, setScreenSize] = useState<string>("");

	useEffect(() => {
		function handleResize() {
			const width = window.innerWidth;
			if (width <= 600) {
				setScreenSize("small");
			} else if (width >= 600 && width <= 1199) {
				setScreenSize("medium");
			} else {
				setScreenSize("large");
			}
		}

		handleResize(); // Call it once to set the initial screen size

		// Attach the event listener
		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const fetchOverview = async () => {
			if (session?.access_token) {
				try {
					const response = await getUserPayments(session.access_token);
					setUserPayments(response);
				} catch (error) {
					console.error("Failed to fetch payments:", error);
				}
			}
		};
		fetchOverview();
	}, [session]);

	return userPayments?.transactions ? (
		screenSize === "large" ? (
			<div className={styles.listContainer}>
				<div className={styles.listHeadContainer}>
					<div className={styles.listHead}>
						<span>{t("text.payments.date")}</span>
						<span>{t("text.payments.failed_msg")}</span>
						<span>{t("text.payments.reference_no")}</span>
						<span> {t("text.payments.status")}</span>
						<span> {t("text.payments.update_at")}</span>
					</div>
				</div>
				<div className={styles.tableList}>
					{userPayments.transactions.map((payment) => (
						<div
							key={payment.id}
							className={styles.tableWrapper}
						>
							<span className={styles.headItem}>
								{new Date(payment.createdAt).toLocaleDateString()}
							</span>
							<span className={styles.headItem}>
								{payment.failedReasonMsg || "N/A"}
							</span>
							<span className={styles.headItem}>{payment.referenceNo}</span>
							<span className={styles.headItem}>{payment.status}</span>
							<span className={styles.headItem}>
								{new Date(payment.updatedAt).toLocaleDateString()}
							</span>
						</div>
					))}
				</div>
			</div>
		) : (
			<div className={styles.resTableList}>
				<h2>{t("text.payments.page_name")} </h2>
				{userPayments.transactions.map((payment) => (
					<div
						key={payment.id}
						className={styles.resTableWrapper}
					>
						<div className={styles.resCardItem}>
							<span className={styles.listItemTitle}>Date: </span>
							<span className={styles.headItem}>
								{new Date(payment.createdAt).toLocaleDateString()}
							</span>
						</div>
						<div className={styles.resCardItem}>
							<span className={styles.listItemTitle}>Updated At: </span>
							<span className={styles.headItem}>
								{new Date(payment.updatedAt).toLocaleDateString()}
							</span>
						</div>

						<div className={styles.resCardItem}>
							<span className={styles.listItemTitle}>Failed Reason Msg: </span>
							<span className={styles.headItem}>
								{payment.failedReasonMsg || "N/A"}
							</span>
						</div>
						<div className={styles.resCardItem}>
							<span className={styles.listItemTitle}>Status: </span>
							<span className={styles.headItem}>{payment.status}</span>
						</div>

						<div>
							<span className={styles.listItemTitle}>Reference No: </span>
							<span className={styles.headItem}>{payment.referenceNo}</span>
						</div>
					</div>
				))}
			</div>
		)
	) : (
		<NoDataResult
			firstMessage={t("text.payments.firstMessage")}
			secondMessage={t("text.payments.secondMessage")}
		/>
	);
};

export default ProfilePayments;
