import { useState, useEffect, useMemo } from "react";
import Select, { StylesConfig, SingleValue, ActionMeta } from "react-select";
import styles from "./phoneNumberSelector.module.css";
import countryCodes from "country-codes-list";
import { useTranslation } from "react-i18next";

interface PrefixOption {
  _id: number;
  label: string; // Display format: 'CountryCode / +CallingCode'
  code: string; // This will hold the actual calling code like '+90'
}

const customStyle: StylesConfig<PrefixOption, false> = {
  control: (styles) => ({
    ...styles,
    borderRadius: "5px",
    border: "none",
    backgroundColor: "var(--light_bg-color)",
    height: "42px",
  }),
};

interface PhoneNumberSelectorProps {
  initialPhone: string; // Prop for the initial phone number
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
}

const PhoneNumberSelector: React.FC<PhoneNumberSelectorProps> = ({
  initialPhone,
  setPhoneNumber,
}) => {
  const [selectedPrefix, setSelectedPrefix] = useState<PrefixOption | null>(
    null
  );
  const [existPhoneNumber, setExistPhoneNumber] = useState<string>("");
  const { t } = useTranslation("atwallet");
  // Memoize the prefix options to prevent recalculations on every render
  const prefixOptions = useMemo(
    () =>
      countryCodes.all().map((country, index) => ({
        _id: index + 1,
        label: `${country.countryCode} / +${country.countryCallingCode}`, // Format: AD / +376
        code: `+${country.countryCallingCode}`, // Store the calling code (e.g., +376)
      })),
    []
  );

  useEffect(() => {
    if (initialPhone) {
      // Extract the last 10 characters as the phone number
      const number = initialPhone.slice(-10);
      const prefix = initialPhone.slice(0, -10); // Everything before the last 10 characters

      // Match the prefix against known country codes
      const matchingPrefix = prefixOptions.find((option) =>
        prefix.endsWith(option.code)
      );

      // Only update state if there is a valid match and no loop
      setSelectedPrefix(matchingPrefix || null);
      setExistPhoneNumber(number);
    }
  }, [initialPhone, prefixOptions]);

  const handlePrefixChange = (
    newValue: SingleValue<PrefixOption>,
    actionMeta: ActionMeta<PrefixOption>
  ) => {
    setSelectedPrefix(newValue);
    if (newValue) {
      setPhoneNumber(`${newValue.code}${existPhoneNumber}`);
    }
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const phoneNumber = event.target.value;
    setExistPhoneNumber(phoneNumber);

    // Update full phone number with prefix and number
    if (selectedPrefix) {
      setPhoneNumber(`${selectedPrefix.code}${phoneNumber}`);
    }
  };

  return (
    <div className={styles.inputs_container}>
      {/* Phone Prefix Selector */}
      <div className={styles.area_code}>
        <label htmlFor="phone-prefix-select" className="input_label">
          {t("text.inputs.area_code")}
        </label>
        <Select
          id="phone-prefix-select"
          options={prefixOptions}
          styles={customStyle}
          onChange={handlePrefixChange}
          value={selectedPrefix}
          isClearable
        />
      </div>

      {/* Phone Number Input */}
      <div className={styles.input_container}>
        <label htmlFor="phone-number-input" className="input_label">
          {t("text.inputs.phone")}
        </label>
        <div className={styles.input_wrapper}>
          <span className="material-icons">phone_iphone</span>
          <input
            id="phone-number-input"
            type="text"
            value={existPhoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberSelector;
