import Image from "next/image";
import styles from "./filters.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface Props {
     firstMessage: string;
     secondMessage: string;
}

const NoDataResult: React.FC<Props> = ({ firstMessage, secondMessage }) => {
     const { t } = useTranslation("atwallet");
     const [screenSize, setScreenSize] = useState<string>("");

     useEffect(() => {
          function handleResize() {
               const width = window.innerWidth;
               if (width <= 600) {
                    setScreenSize("small");
               } else if (width >= 600 && width <= 1199) {
                    setScreenSize("medium");
               } else {
                    setScreenSize("large");
               }
          }

          handleResize(); // Call it once to set the initial screen size

          // Attach the event listener
          window.addEventListener("resize", handleResize);

          // Clean up the event listener when the component unmounts
          return () => window.removeEventListener("resize", handleResize);
     }, []);

     return (
          <div className={styles.noResultsContainer}>
               <div className={screenSize == "large" ? styles.noResultsWrapper : styles.resNoResultsWrapper}>
                    <Image src="/images/Currency-rafiki.svg" width={300} height={200} alt="" />
                    <h3>{firstMessage}</h3>
                    <h5>{secondMessage}</h5>
               </div>
          </div>
     );
};

export default NoDataResult;
