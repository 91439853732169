import Image from "next/image";
import styles from "./profileGeneral.module.css";
import PrimaryInput from "@/components/base/inputs/primaryInput";
import PhoneNumberSelector from "@/components/base/dropdown/phoneNumberSelector/phoneNumberSelector";
import { UserInfoType } from "@/_core/models/userType";
import { useState, useEffect } from "react";
import CountrySelect from "@/components/base/inputs/country-city/countryInput";
import { updateUserInfo } from "@/_core/services/userService";
import { useSession } from "next-auth/react";
import { CurrencyOption } from "@/_core/models/currencyType";
import CurrencyTypeSelectors from "@/components/base/dropdown/currencyTypeSelector/currencyTypeSelector";
import { useTranslation } from "react-i18next";

interface Props {
	user: UserInfoType | null;
	setUser: React.Dispatch<React.SetStateAction<UserInfoType | null>>;
}

const ProfileGeneral: React.FC<Props> = ({ user, setUser }) => {
	const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone || "");
	const [firstname, setFirstName] = useState<string>(user?.firstname || "");
	const [lastname, setLastName] = useState<string>(user?.lastname || "");
	const [country, setCountry] = useState<string>(user?.country || "");
	const [changedCurrency, setChangedCurrency] = useState<CurrencyOption>();
	const { data: session, update } = useSession();

	const { t } = useTranslation("atwallet");
	const updatedUserData: Partial<any> = {
		firstname,
		lastname,
		country,
		phone: phoneNumber,
		currency: changedCurrency?.id, // Store only the currency ID
	};

	const existingUserData = user && user;

	// Update state when user prop changes
	useEffect(() => {
		if (user) {
			setPhoneNumber(user.phone || "");
			setFirstName(user.firstname || "");
			setLastName(user.lastname || "");
			setCountry(user.country || "");
		}
	}, [user]); // Dependency array ensures this runs when user changes

	const changeHandler = async () => {
		const accessToken = session?.access_token ?? "";
		if (accessToken && existingUserData) {
			try {
				const response = await updateUserInfo(
					accessToken,
					updatedUserData,
					existingUserData,
				);
				setUser(response);

				// Check if session exists before updating
				if (session) {
					update({ ...session, user: { ...session.user, ...response } });
				} else {
					console.error("Session does not exist");
				}
			} catch (error) {
				console.error("Failed to update user info", error);
			}
		} else {
			console.error("No access token or user data available");
		}
	};

	const [screenSize, setScreenSize] = useState<string>("");

	useEffect(() => {
		function handleResize() {
			const width = window.innerWidth;
			if (width <= 600) {
				setScreenSize("small");
			} else if (width >= 600 && width <= 1199) {
				setScreenSize("medium");
			} else {
				setScreenSize("large");
			}
		}

		handleResize(); // Call it once to set the initial screen size

		// Attach the event listener
		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		user && (
			<div
				className={
					screenSize === "large"
						? styles.main_container
						: styles.resMain_container
				}
			>
				<>
					{screenSize === "large" ? (
						<div className={styles.head_container}>
							<div className={styles.head_text}>
								{<h1>{t("text.account.general")}</h1>}
								<p>{t("text.account.general_message")}</p>
							</div>
							<button
								className="primaryButton"
								onClick={changeHandler}
							>
								{t("text.buttons.save-changes")}
							</button>
						</div>
					) : (
						<div className={styles.resHead_container}>
							<div className={styles.resHead_text}>
								<h2>{t("text.account.general")}</h2>
								<button
									className="primaryButton"
									onClick={changeHandler}
								>
									{t("text.buttons.save-changes")}
								</button>
							</div>
							<p>{t("text.account.general_message")}</p>
						</div>
					)}

					<div className="primaryVerticalLine"></div>
					<div className={styles.profile_owner_container}>
						<div className={styles.profile_owner_text}>
							{firstname && lastname ? `${firstname[0]}${lastname[0]}` : "N/A"}
						</div>
						<div className={styles.profile_owner}>
							<h1>{`${firstname} ${lastname}`}</h1>
						</div>
					</div>
				</>
				<div
					className={
						screenSize === "large"
							? styles.profile_informations
							: styles.resProfile_informations
					}
				>
					<div
						className={
							screenSize === "large"
								? styles.profile_personal_informations
								: styles.resProfile_personal_informations
						}
					>
						<h3>{t("text.account.personal_information")}</h3>
						<PrimaryInput
							inputName={t("text.inputs.firstname")}
							type="text"
							placeholder={t("text.inputs.firstname")}
							icon="badge"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setFirstName(e.target.value)
							}
							value={firstname}
						/>
						<PrimaryInput
							inputName={t("text.inputs.lastname")}
							type="text"
							placeholder={t("text.inputs.lastname")}
							icon="badge"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setLastName(e.target.value)
							}
							value={lastname}
						/>
						<CountrySelect
							setCountry={setCountry}
							existingCountry={user.country ?? ""}
						/>
						<CurrencyTypeSelectors
							setChangedCurrency={setChangedCurrency}
							userCurrencyId={user?.currency}
						/>
					</div>
					<div className={styles.profile_contact_informations}>
						<h3>{t("text.account.contact_information")}</h3>
						<PhoneNumberSelector
							setPhoneNumber={setPhoneNumber}
							initialPhone={phoneNumber}
						/>
						<PrimaryInput
							inputName={t("text.inputs.email")}
							type="email"
							placeholder={t("text.inputs.email")}
							icon="email"
							onChange={null}
							value={user.email || ""}
						/>
					</div>
				</div>
			</div>
		)
	);
};

export default ProfileGeneral;
